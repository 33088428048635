import {
  type ALLOWED_HOSTS,
  hostToTenantConfiguration,
} from './hostToTenantConfiguration'
import { SupportedLanguages } from './tenantLanguagesConfig'
import {
  SHOP_ENV,
  TENANT,
  TENANT_AND_ENV,
  type TenantData,
} from './tenantTypes'

const isShopEnv = (env: string): env is SHOP_ENV =>
  Object.values(SHOP_ENV).includes(env as SHOP_ENV)

/**
 * typeguard to avoid casts
 */
const tenantAndEnvAccessorIsTenantType = (tenant: string): tenant is TENANT =>
  Object.values(TENANT).includes(tenant as TENANT)

export const tenantAndEnvAccessorIsTenantAndEnvType = (
  tenantAndEnv: string,
): tenantAndEnv is TENANT_AND_ENV =>
  Object.values(TENANT_AND_ENV).includes(tenantAndEnv as TENANT_AND_ENV)

/**
 * ('com') -> {tenant: 'com', shopEnv: 'prod', combinedTenantAndEnv: 'com'}
 * ('com-qs') -> {tenant: 'com', shopEnv: 'qs', combinedTenantAndEnv: 'com-qs'}
 */
export const tenantAndEnvAccessorGetTenantDataFromCombinedTenantAndEnv = (
  combinedTenantAndEnv: TENANT_AND_ENV,
): TenantData => {
  const [tenant, shopEnv] = combinedTenantAndEnv.split('-')

  if (!tenantAndEnvAccessorIsTenantType(tenant)) {
    throw new Error(`Invalid_tenant_${tenant}`)
  }

  if (shopEnv) {
    if (!isShopEnv(shopEnv)) {
      throw new Error('invalid_ENV')
    }

    return {
      shopEnv,
      tenant,
      tenantAndEnv: combinedTenantAndEnv,
    }
  }

  return {
    shopEnv: SHOP_ENV.PROD,
    tenant,
    tenantAndEnv: combinedTenantAndEnv,
  }
}

const hasConfigurationForHost = (host: string): host is ALLOWED_HOSTS =>
  Boolean(hostToTenantConfiguration[host as ALLOWED_HOSTS])

const processEnvAccessorGetForceTenantAndEnv = ():
  | TENANT_AND_ENV
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- looks like false positive
  | undefined => process.env.FORCE_TENANT_AND_ENV_ON_DEVELOPMENT

export const shouldForceTenantInDevelopment = (): boolean => {
  return Boolean(processEnvAccessorGetForceTenantAndEnv())
}

export const getTenantDataFromForcedTenant = (): TenantData => {
  const forcedTenant = processEnvAccessorGetForceTenantAndEnv()

  if (!forcedTenant) {
    throw new Error('missing_FORCE_TENANT_AND_ENV_ON_DEVELOPMENT_env')
  }

  return tenantAndEnvAccessorGetTenantDataFromCombinedTenantAndEnv(forcedTenant)
}

/**
 * Returns tenant and shop env depending on the host:
 * e.g.
 * 'shop-apotheke.com' -> {tenant: com, shopEnv: prod, combinedTenantAndEnv: 'com'}
 * 'shop-apotheke-com-qs.redteclab.de' -> {tenant: com, shopEnv: qs, combinedTenantAndEnv: 'com-qs'}
 */
export const tenantAndEnvAccessorInferTenantAndShopEnvFromHost = (
  host: string,
): TenantData => {
  /**
   * We need to check against lowercased host because we read it from header where case could be preserved.
   * There was a known occurence where facebook bot were acceessing with "Farmaline",
   * and that could not be correctly mapped.
   */
  const lowerCaseHost = host.toLowerCase()

  if (!hasConfigurationForHost(lowerCaseHost)) {
    throw new Error(`invalid_host_cant_be_mapped: ${lowerCaseHost}`)
  }

  return hostToTenantConfiguration[lowerCaseHost]
}

export const tenantAndEnvAccessorIsValidLanguage = (
  language: string,
): language is SupportedLanguages =>
  Object.values(SupportedLanguages).includes(language as SupportedLanguages)

import { type FC } from 'react'

import { AppRouterMigrationHead } from '../../../app-router-migration'

type SeoHeadTitleProps = {
  title: string
}

export const SeoHeadTitleMetas: FC<SeoHeadTitleProps> = ({ title }) => {
  return (
    <AppRouterMigrationHead>
      <title key="title">{title}</title>
      <meta content={title} key="og:title" property="og:title" />
      <meta content={title} key="twitter:title" property="twitter:title" />
    </AppRouterMigrationHead>
  )
}

import { type FC } from 'react'

import { StaticImage } from '../../../image'

import watermark from './watermark.svg'

import styles from './StoryblokWatermark.module.css'

export const StoryblokWatermark: FC = () => (
  <StaticImage
    alt=""
    className={styles.watermark}
    src={watermark}
    width={200}
  />
)

'use client'

import {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
} from 'react'

import { type CrossSellConfiguration } from '../../config/crossSellGetConfiguration'

export type CrossSellConfigContext = Pick<
  CrossSellConfiguration,
  'exceptionFlags' | 'widgets'
>

const Context = createContext<CrossSellConfigContext | undefined>(undefined)

export const useCrossSellConfigContext = (): CrossSellConfigContext => {
  const contextValue = useContext(Context)

  if (contextValue === undefined) {
    throw new Error('NO_CROSS_SELL_CONFIG_PROVIDER')
  }

  return contextValue
}

type CrossSellConfigProviderProps = {
  value: CrossSellConfigContext
}

export const CrossSellConfigProvider: FC<
  PropsWithChildren<CrossSellConfigProviderProps>
> = ({ children, value }) => (
  <Context.Provider value={value}>{children}</Context.Provider>
)

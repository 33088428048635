'use client'

import { type FC } from 'react'

import { AppRouterMigrationHead } from '../app-router-migration'
import { SeoHeadDescriptionMetas, SeoHeadTitleMetas } from '../seo'

import { type CmsAlternateUrlsMetaData } from './model/storyAccessors/CmsAlternateUrlsMetaData'
import { useGetStoryblokSeoParameters } from './useGetStoryblokSeoParameters'

type Props = {
  hrefLangs: CmsAlternateUrlsMetaData[]
}

export const CmsSeo: FC<Props> = ({ hrefLangs }) => {
  const { canonicalUrl, description, title } = useGetStoryblokSeoParameters()

  return (
    <>
      <SeoHeadTitleMetas title={title} />
      {description ? (
        <SeoHeadDescriptionMetas description={description} />
      ) : null}
      <AppRouterMigrationHead>
        <link href={canonicalUrl} rel="canonical" />
        {hrefLangs.map((item) => (
          <link
            href={item.href}
            hrefLang={item.hrefLang}
            key={item.href}
            rel="alternate"
          />
        ))}
        <meta charSet="utf8" />
        <meta content={title} name="name" />
        <meta content="index, follow, noodp" name="robots" />
        <meta content={canonicalUrl} property="og:url" />
        <meta content="website" property="og:type" />
        <meta content="summary" name="twitter:card" />
      </AppRouterMigrationHead>
    </>
  )
}

import { type StoryblokAdBannerType } from '../../model/storyblokTypes.generated'

export type StoryblokAdBannerDimensions = {
  height: number
  width: number
}

export const storyblokAdBannerParseDimensions = (
  dimensions:
    | StoryblokAdBannerType['dimensions']
    | StoryblokAdBannerType['dimensionsMobile'],
): StoryblokAdBannerDimensions | undefined => {
  if (!dimensions) {
    return undefined
  }

  try {
    const parsed = JSON.parse(dimensions) as StoryblokAdBannerDimensions

    if (!parsed.height || !parsed.width) {
      return undefined
    }

    return parsed
  } catch {
    return undefined
  }
}

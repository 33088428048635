import { apmCaptureError } from '../apm'
import { CustomError } from '../base-errors'
import { getTenantLocale } from '../global-config'
import {
  TENANT_AND_ENV_TO_HOST,
  tenantAndEnvAccessorGetTenantDataFromCombinedTenantAndEnv,
  tenantAndEnvAccessorIsTenantAndEnvType,
  tenantAndEnvAccessorIsValidLanguage,
} from '../tenant'
import { urlInstanceToUriString, urlNormalizeUri } from '../url-handling'

/**
 * We extract the thenant annd env and the language from the internal storyblok slug.
 * example:
 * /com-qs-de/foo => { maybeLanguage: 'de', maybeTenantAndEnvPart: 'com-qs' }
 * /ch-fr/foo => { maybeLanguage: 'fr', maybeTenantAndEnvPart: 'ch' }
 */
const getTenantInfoFromStoryblokSlug = (
  url: URL,
): { maybeLanguage: string; maybeTenantAndEnvPart: string } => {
  // Get the pathname and split it into parts
  const urlPart = url.pathname.split('/').find(Boolean)

  const tenantAndEnvAndLanguage = urlPart ?? ''

  const languageSeparatorIndex = tenantAndEnvAndLanguage.lastIndexOf('-')

  const maybeTenantAndEnvPart = tenantAndEnvAndLanguage.slice(
    0,
    languageSeparatorIndex,
  )

  const maybeLanguage = tenantAndEnvAndLanguage.slice(
    languageSeparatorIndex + 1,
  )

  return { maybeLanguage, maybeTenantAndEnvPart }
}

const handleTenantAndEnvInSlug = (
  url: URL,
  {
    addTenantHost,
  }: {
    addTenantHost: boolean | undefined
  },
): URL => {
  const { maybeLanguage, maybeTenantAndEnvPart } =
    getTenantInfoFromStoryblokSlug(url)

  try {
    if (
      tenantAndEnvAccessorIsTenantAndEnvType(maybeTenantAndEnvPart) &&
      tenantAndEnvAccessorIsValidLanguage(maybeLanguage)
    ) {
      /*
       * Get the pathname and split it into parts
       * ['','com-qs-de','foo', 'bar'] => 'foo/bar'
       */
      const parts = url.pathname.split('/').filter(Boolean).slice(1)

      url.pathname = `/${parts.join('/')}`
      // append tenant host depending on option
      if (addTenantHost) {
        url.hostname = TENANT_AND_ENV_TO_HOST[maybeTenantAndEnvPart]
        url.protocol = 'https:'
      }

      const { tenant } =
        tenantAndEnvAccessorGetTenantDataFromCombinedTenantAndEnv(
          maybeTenantAndEnvPart,
        )

      const locale = getTenantLocale(tenant, maybeLanguage)

      /**
       * run normalize as for other links,
       * important that, on storyblok language is not included in slug, so this as
       * well prefixes with language for multilang tenantns.
       */
      url.pathname = urlNormalizeUri({
        locale,
        uri: url.pathname,
      })
    }

    return url
  } catch (error) {
    apmCaptureError(
      new CustomError(
        {
          message: url.toString(),
          name: 'FAILED_TO_TRANSFORM_STORYBLOK_SLUG_TO_PUBLIC_URL',
        },
        {
          cause: error,
        },
      ),
    )

    return url
  }
}

/**
 * on Storyblok we use dir structure where content for each tenant is put into tenant specific dir
 * e.g. com-qs-de, com-de, fr-qs-fr, fr-fr, ...
 *
 * this function transforms the storyblok slug to public url we use on the site.
 * so it's basically an opposite of {@link cmsPublicUrlToStoryblokSlug}
 *
 * @param options.addTenantHostFromPathPrefix - if true, the tenant host will be inferred from path and it's host added to the url
 *
 * @example
 * / => /
 * /com-qs-de/foo => /foo/
 * (/com-qs-de/foo, {addTenantHostFromPathPrefix: true}) => https://com-qs.redteclab.de/foo/
 * (/ch-qs-fr/foo, {locale: 'fr-CH'}) => /fr/foo/
 */
export const cmsStoryblokSlugToPublicUrl = (
  storyblokSlug: string,
  options: { addTenantHostFromPathPrefix?: boolean } = {},
): string => {
  /**
   * on storyblok root dir always includes tenant.
   * at this step tenant part is removed from slug,
   * and if option.addTenantHostFromPathPrefix is true, tenant host is added to the url.
   */
  const publicUrl = handleTenantAndEnvInSlug(
    new URL(storyblokSlug, 'https://localhost'),
    {
      addTenantHost: options.addTenantHostFromPathPrefix,
    },
  )

  /**
   * run normalize as for other links,
   * important that, on storyblok language is not included in slug, so this as
   * well prefixes with language for multilang tenantns.
   */
  publicUrl.pathname = urlNormalizeUri({
    uri: publicUrl.pathname,
  })

  // if depending on option result url shall not include host (instance has default fallback host)
  if (publicUrl.host === 'localhost') {
    return urlInstanceToUriString(publicUrl)
  }

  return publicUrl.toString()
}

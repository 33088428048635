import { useStoryblokStory } from './context/StoryblokStoryContext'
import { getStoryblokCanonicalUrl } from './getStoryblokCanonicalUrl'

type SeoParameters = {
  canonicalUrl: string
  description?: string
  title: string
}

export const useGetStoryblokSeoParameters = (): SeoParameters => {
  const story = useStoryblokStory()
  const {
    content: { description, title = '' },
  } = story

  return {
    canonicalUrl: getStoryblokCanonicalUrl(story),
    description,
    title,
  }
}

import { type FC } from 'react'

import { AppRouterMigrationHead } from '../../../app-router-migration'

type SeoTitleDescriptionProps = {
  description: string
}

export const SeoHeadDescriptionMetas: FC<SeoTitleDescriptionProps> = ({
  description,
}) => {
  return (
    <AppRouterMigrationHead>
      <meta content={description} key="description" name="description" />
      <meta
        content={description}
        key="twitter:description"
        property="twitter:description"
      />
      <meta
        content={description}
        key="og:description"
        property="og:description"
      />
    </AppRouterMigrationHead>
  )
}

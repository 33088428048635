'use client'

import { type FC, useEffect } from 'react'

import { useAdServerConfigurationContext } from '../ad-server'

import { type CmsPageProps } from './cmsPagesGetServerSideProps'

export const CmsLoadBanners: FC<{
  pageBanners: CmsPageProps['pageBanners']
}> = ({ pageBanners }) => {
  const { initBanners } = useAdServerConfigurationContext()

  useEffect(() => {
    initBanners(pageBanners)
  }, [initBanners, pageBanners])

  return null
}

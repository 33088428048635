import { type NextPage } from 'next'

import { CrossSellConfigProvider } from '../cross-sell'
import { StandardShopLayout } from '../standard-shop-layout'

import { CmsLoadBanners } from './CmsLoadBanners'
import { type CmsPageProps } from './cmsPagesGetServerSideProps'
import { CmsSeo } from './CmsSeo'
import { StoryblokMigrationStory } from './components/StoryblokMigrationStory'
import { StoryblokWatermark } from './components/StoryblokWatermark'
import { StoryblokStoryProvider } from './context/StoryblokStoryContext'
import { StoryblokServerSideDataProvider } from './storyblok-server-side-data/context/storyblokServerSideDataContext'

export const CmsPage: NextPage<CmsPageProps> = ({
  crossSellConfig,
  hrefLangs,
  pageBanners,
  preview,
  standardLayoutData,
  story,
  storyServerSideData,
}) => {
  return (
    <StandardShopLayout standardLayoutData={standardLayoutData}>
      <CrossSellConfigProvider value={crossSellConfig}>
        <StoryblokServerSideDataProvider
          storyblokServerSideData={storyServerSideData}
        >
          <StoryblokStoryProvider story={story}>
            <CmsLoadBanners pageBanners={pageBanners} />
            <CmsSeo hrefLangs={hrefLangs} />
            <StoryblokMigrationStory story={story} />
          </StoryblokStoryProvider>
        </StoryblokServerSideDataProvider>
      </CrossSellConfigProvider>
      {preview ? <StoryblokWatermark /> : null}
    </StandardShopLayout>
  )
}

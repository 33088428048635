'use client'

import {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
} from 'react'

import { type storyblokComponentServerSideHandlers } from '../storyblokComponentServerSideHandlers'
import { type StoryblokServerSideData } from '../StoryblokServerSideData'
import { type StoryblokServerSideHandledComponentType } from '../storyblokServerSideHandledComponentType'

type StoryblokComponentServerSideData<
  T extends StoryblokServerSideHandledComponentType,
> =
  | {
      data: Awaited<
        ReturnType<
          (typeof storyblokComponentServerSideHandlers)[T['component']]
        >
      >
      error: never
    }
  | {
      data: never
      error: string | true
    }

const StoryblokServerSideDataContext =
  createContext<StoryblokServerSideData | null>(null)

type StoryblokServerSideDataProviderProps = PropsWithChildren<{
  storyblokServerSideData: StoryblokServerSideData
}>

export const StoryblokServerSideDataProvider: FC<
  StoryblokServerSideDataProviderProps
> = ({ children, storyblokServerSideData }) => {
  return (
    <StoryblokServerSideDataContext.Provider value={storyblokServerSideData}>
      {children}
    </StoryblokServerSideDataContext.Provider>
  )
}

export const useStoryblokServerSideData = <
  T extends StoryblokServerSideHandledComponentType,
>(
  blok: T,
): {
  data?: Awaited<
    ReturnType<(typeof storyblokComponentServerSideHandlers)[T['component']]>
  >

  /**
   * Values:
   * * `true` - for production deployment to prevent sensitive data leakage
   * * `string` - for development purpose to display error message in a component
   */
  error?: string | true
} => {
  const context = useContext(StoryblokServerSideDataContext)

  if (context === null) {
    throw new Error(
      'StoryblokServerSideDataContext must be used within a StoryblokServerDataProvider',
    )
  }

  const { _uid: uid } = blok

  if (!context[uid]) {
    throw new Error(
      `no storyblokComponentNameToServerSideHandler provided for component with uid "${uid}"`,
    )
  }

  return context[uid] as StoryblokComponentServerSideData<T>
}

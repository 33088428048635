import { apiPlugin, storyblokInit } from '@storyblok/react'

import {
  processEnvAccessorGetStoryblokAccessToken,
  processEnvAccessorGetStoryblokCacheServiceUrl,
} from '../process-env'

import { STORYBLOK_COMPONENTS_MAP } from './components/STORYBLOK_COMPONENTS_MAP'

let initialized = false

/**
 * Initializes the Storyblok SDK for Pages Router. Internally, the library will create a singleton instance
 * that can be retrieved using `getStoryblokApi` from `@storyblok/react`.
 */
export const cmsInitializeStoryblokApi = (): void => {
  if (initialized) {
    return
  }

  storyblokInit({
    accessToken: processEnvAccessorGetStoryblokAccessToken(),
    apiOptions: {
      endpoint: processEnvAccessorGetStoryblokCacheServiceUrl(),
    },
    components: STORYBLOK_COMPONENTS_MAP,
    use: [apiPlugin],
  })

  initialized = true
}

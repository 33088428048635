'use client'

import {
  type ISbStoryData,
  StoryblokComponent,
  useStoryblokState,
} from '@storyblok/react'
import { StoryblokStory } from '@storyblok/react/rsc'
import { type FC } from 'react'

import { useAppRouterMigrationContext } from '../../../app-router-migration'
import { type StoryblokContentTypePage } from '../../model/StoryblokContentTypePage'

type StoryblokMigrationStoryProps = {
  story: ISbStoryData<StoryblokContentTypePage>
}

export const StoryblokMigrationStory: FC<StoryblokMigrationStoryProps> = ({
  story: initialStory,
}) => {
  const { isAppRouter } = useAppRouterMigrationContext()
  const story = useStoryblokState(initialStory)

  if (isAppRouter) {
    return <StoryblokStory story={initialStory} />
  }

  return <StoryblokComponent blok={story?.content} />
}

import { storyblokEditable } from '@storyblok/react/rsc'
import { type FC } from 'react'

import { AdServerBanner } from '../../../ad-server'
import { apmCaptureError } from '../../../apm'
import { useDeviceDetectorContext } from '../../../device-detector'
import { type StoryblokComponentProps } from '../../model'
import { type StoryblokAdBannerType } from '../../model/storyblokTypes.generated'

import { storyblokAdBannerGetDimensionsByDevice } from './storyblokAdBannerGetDimensionsByDevice'
import { type StoryblokAdBannerDimensions } from './storyblokAdBannerParseDimensions'

/**
 * parse and return dimensions for ad banner
 *
 */
const useDimensions = (
  blok: StoryblokAdBannerType,
): StoryblokAdBannerDimensions | undefined => {
  const { isMobile } = useDeviceDetectorContext()

  return storyblokAdBannerGetDimensionsByDevice(blok, isMobile)
}

export const StoryblokAdBanner: FC<
  StoryblokComponentProps<StoryblokAdBannerType>
> = ({ blok }) => {
  const dimensions = useDimensions(blok)

  if (!blok.adServerBannerTags) {
    apmCaptureError(new Error('SB_AD_BANNER_MISSING_BANNER_TAGS'))

    return null
  }

  if (!dimensions) {
    return null
  }

  return (
    <AdServerBanner
      {...storyblokEditable(blok)}
      bannerTags={[blok.adServerBannerTags]}
      dimensions={dimensions}
      showSkeleton
    />
  )
}

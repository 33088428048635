import { type StoryblokAdBannerType } from '../../model/storyblokTypes.generated'

import {
  type StoryblokAdBannerDimensions,
  storyblokAdBannerParseDimensions,
} from './storyblokAdBannerParseDimensions'

export const storyblokAdBannerGetDimensionsByDevice = (
  blok: StoryblokAdBannerType,
  isMobile: boolean,
): StoryblokAdBannerDimensions | undefined => {
  if (isMobile && blok.dimensionsMobile) {
    return storyblokAdBannerParseDimensions(blok.dimensionsMobile)
  }

  if (blok.dimensions) {
    return storyblokAdBannerParseDimensions(blok.dimensions)
  }

  return undefined
}

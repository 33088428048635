import url from 'node:url'

import { type GetServerSideProps, type GetServerSidePropsResult } from 'next'

import { apmSetTransactionName } from '../../../domain/apm'
import {
  cmsInitializeStoryblokApi,
  type CmsPageProps,
  cmsPagesGetServerSideProps,
} from '../../../domain/cms'
import { getServerSideLocaleMessages } from '../../../domain/i18n'
import {
  getServerSideAppProps,
  mapParsedUrlQueryToPlainRecord,
} from '../../../domain/next-compose'

export { CmsPage as default } from '../../../domain/cms'

cmsInitializeStoryblokApi()

const getCmsServerSideProps = (
  filename: string,
): GetServerSideProps<CmsPageProps, { slug: string }> => {
  return async (context): Promise<GetServerSidePropsResult<CmsPageProps>> => {
    const { params, query } = context

    apmSetTransactionName({
      filename,
    })

    const [, appPromises] = getServerSideAppProps(context)

    const globalConfig = await appPromises.globalConfig

    const localeMessagesPromise = getServerSideLocaleMessages(
      globalConfig.privateGlobalConfig.locale,
      filename,
    )

    const pageProps = await cmsPagesGetServerSideProps({
      localeMessagesPromise,
      pageOrAppRouterContext: context,
      params: {
        language: globalConfig.privateGlobalConfig.language,
        slug: params?.slug ?? '',
        tenant: globalConfig.privateGlobalConfig.tenant,
      },
      searchParams: mapParsedUrlQueryToPlainRecord(query),
    })

    if ('notFound' in pageProps) {
      return pageProps
    }

    if ('redirect' in pageProps) {
      return pageProps
    }

    return { props: pageProps }
  }
}

export const getServerSideProps = getCmsServerSideProps(
  url.fileURLToPath(import.meta.url),
)

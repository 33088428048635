import { SHOP_ENV, TENANT, TENANT_AND_ENV } from './tenantTypes'

export enum ALLOWED_HOSTS {
  AT_PROD = 'www.shop-apotheke.at',
  AT_QS = 'shop-apotheke-at-qs.redteclab.de',
  BE_PROD = 'www.farmaline.be',
  BE_QS = 'farmaline-be-qs.redteclab.de',

  CH_PROD = 'www.redcare-apotheke.ch',
  CH_QS = 'redcare-apotheke-ch-qs.redteclab.de',
  COM_PROD = 'www.shop-apotheke.com',
  COM_QS = 'shop-apotheke-com-qs.redteclab.de',

  /**
   * Old France domain could be in bookmarks or still stored in some services
   */
  FR_OLD_PROD = 'www.shop-pharmacie.fr',

  FR_PROD = 'www.redcare-pharmacie.fr',
  FR_QS = 'redcare-pharmacie-fr-qs.redteclab.de',
  IT_PROD = 'www.redcare.it',
  IT_QS = 'redcare-it-qs.redteclab.de',
}

/**
 * PRELIMINARY SOLUTION:
 * we will identify tenant and shop env for each request depending on origin host of the incoming request.
 * this represents configuration map of host to a corresponding tenant and shop env.
 */
export const hostToTenantConfiguration: Record<
  ALLOWED_HOSTS,
  {
    shopEnv: SHOP_ENV
    tenant: TENANT
    tenantAndEnv: TENANT_AND_ENV
  }
> = {
  [ALLOWED_HOSTS.AT_PROD]: {
    shopEnv: SHOP_ENV.PROD,
    tenant: TENANT.AT,
    tenantAndEnv: TENANT_AND_ENV.AT,
  },
  [ALLOWED_HOSTS.AT_QS]: {
    shopEnv: SHOP_ENV.QS,
    tenant: TENANT.AT,
    tenantAndEnv: TENANT_AND_ENV.AT_QS,
  },
  [ALLOWED_HOSTS.BE_PROD]: {
    shopEnv: SHOP_ENV.PROD,
    tenant: TENANT.BE,
    tenantAndEnv: TENANT_AND_ENV.BE,
  },
  [ALLOWED_HOSTS.BE_QS]: {
    shopEnv: SHOP_ENV.QS,
    tenant: TENANT.BE,
    tenantAndEnv: TENANT_AND_ENV.BE_QS,
  },
  [ALLOWED_HOSTS.CH_PROD]: {
    shopEnv: SHOP_ENV.PROD,
    tenant: TENANT.CH,
    tenantAndEnv: TENANT_AND_ENV.CH,
  },
  [ALLOWED_HOSTS.CH_QS]: {
    shopEnv: SHOP_ENV.QS,
    tenant: TENANT.CH,
    tenantAndEnv: TENANT_AND_ENV.CH_QS,
  },
  [ALLOWED_HOSTS.COM_PROD]: {
    shopEnv: SHOP_ENV.PROD,
    tenant: TENANT.COM,
    tenantAndEnv: TENANT_AND_ENV.COM,
  },
  [ALLOWED_HOSTS.COM_QS]: {
    shopEnv: SHOP_ENV.QS,
    tenant: TENANT.COM,
    tenantAndEnv: TENANT_AND_ENV.COM_QS,
  },
  [ALLOWED_HOSTS.FR_OLD_PROD]: {
    shopEnv: SHOP_ENV.PROD,
    tenant: TENANT.FR,
    tenantAndEnv: TENANT_AND_ENV.FR,
  },
  [ALLOWED_HOSTS.FR_PROD]: {
    shopEnv: SHOP_ENV.PROD,
    tenant: TENANT.FR,
    tenantAndEnv: TENANT_AND_ENV.FR,
  },
  [ALLOWED_HOSTS.FR_QS]: {
    shopEnv: SHOP_ENV.QS,
    tenant: TENANT.FR,
    tenantAndEnv: TENANT_AND_ENV.FR_QS,
  },
  [ALLOWED_HOSTS.IT_PROD]: {
    shopEnv: SHOP_ENV.PROD,
    tenant: TENANT.IT,
    tenantAndEnv: TENANT_AND_ENV.IT,
  },
  [ALLOWED_HOSTS.IT_QS]: {
    shopEnv: SHOP_ENV.QS,
    tenant: TENANT.IT,
    tenantAndEnv: TENANT_AND_ENV.IT_QS,
  },
}

export const TENANT_AND_ENV_TO_HOST: Record<TENANT_AND_ENV, string> = {
  [TENANT_AND_ENV.AT]: ALLOWED_HOSTS.AT_PROD,
  [TENANT_AND_ENV.AT_QS]: ALLOWED_HOSTS.AT_QS,
  [TENANT_AND_ENV.BE]: ALLOWED_HOSTS.BE_PROD,
  [TENANT_AND_ENV.BE_QS]: ALLOWED_HOSTS.BE_QS,
  [TENANT_AND_ENV.CH]: ALLOWED_HOSTS.CH_PROD,
  [TENANT_AND_ENV.CH_QS]: ALLOWED_HOSTS.CH_QS,
  [TENANT_AND_ENV.COM]: ALLOWED_HOSTS.COM_PROD,
  [TENANT_AND_ENV.COM_QS]: ALLOWED_HOSTS.COM_QS,
  [TENANT_AND_ENV.FR]: ALLOWED_HOSTS.FR_PROD,
  [TENANT_AND_ENV.FR_QS]: ALLOWED_HOSTS.FR_QS,
  [TENANT_AND_ENV.IT]: ALLOWED_HOSTS.IT_PROD,
  [TENANT_AND_ENV.IT_QS]: ALLOWED_HOSTS.IT_QS,
}
